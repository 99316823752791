import React, { createContext, useState } from "react"

const defaultValues = {
  navOpen: false,
  reverseHeader: false,
  formOpen: false,
}

export const SiteContext = createContext(defaultValues)

export const SiteProvider = ({ children }) => {
  const [navOpen, setNavOpen] = useState(defaultValues.navOpen)
  const [formOpen, setFormOpen] = useState(defaultValues.formOpen)
  const [reverseHeader, setReverseHeader] = useState(
    defaultValues.reverseHeader
  )

  return (
    <SiteContext.Provider
      value={{
        ...defaultValues,
        navOpen,
        reverseHeader,
        formOpen,
        setNavOpen,
        setReverseHeader,
        setFormOpen,
      }}
    >
      {children}
    </SiteContext.Provider>
  )
}
