// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-preview-js": () => import("./../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-styles-js": () => import("./../src/pages/styles.js" /* webpackChunkName: "component---src-pages-styles-js" */),
  "component---src-templates-standard-page-js": () => import("./../src/templates/standardPage.js" /* webpackChunkName: "component---src-templates-standard-page-js" */),
  "component---src-templates-use-case-js": () => import("./../src/templates/useCase.js" /* webpackChunkName: "component---src-templates-use-case-js" */)
}

